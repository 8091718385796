import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';
import { unsetJWT } from './user';

function prefixZero(number) {
  if (parseInt(number) < 10) {
    return '0' + number;
  }
  return number;
}

function _setFiltersLoaded(state, value) {
  state.filtersLoaded = value;
  return state;
}
function _setFiltersDisabled(state, value) {
  state.filtersDisabled = value;
  return state;
}
function _resetFilters(state) {
  state.filters.years.selected = '';
  state.filters.sections.selected = '';
  state.filters.campuses.selected = '';
  state.filters.promos.selected = '';
  state.filters.admStatuses.selected = '';
  state.filters.pedagoStatuses.selected = '';
  state.filters.scholarships.selected = '';
  state.filters.scholarshipsLevels.selected = '';
  state.filters.specialScholarships.selected = '';
  state.filters.destinations.selected = '';
  state.filters.futures.selected = '';
  state.filters.futureDestinations.selected = '';
  state.filters.reenrollmentStatuses.selected = '';
  state.filters.seniorities.selected = '';
  state.filters.financialStatuses.selected = '';
  return state;
}
function _setFiltersValues(state, values) {
  for (const item in values) {
    state.filters[item].values = values[item];
  }
  return state;
}
function _setDestinationSettable(enrollments) {
  let destinationSettable = true;
  for (const enrollment of enrollments) {
    if (enrollment.section !== 'PGE 3')
      destinationSettable = false;
  }
  return destinationSettable;
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected');
}

export const fetchFilters = createAsyncThunk('enrollments/fetchFilters', async () => {
  const response = await axiosInstance.get('/values/filters');
  return response.data;
});
export const fetchFutures = createAsyncThunk('enrollments/fetchFutures', async () => {
  const response = await axiosInstance.get('/values/futures');
  return response.data;
});
export const fetchDestinations = createAsyncThunk('enrollments/fetchDestinations', async () => {
  const response = await axiosInstance.get('/values/destinations');
  return response.data;
});
// export const fetchSessions = createAsyncThunk('enrollments/fetchSessions', async () => {
//   const response = await axiosInstance.get('/sessions');
//   return response.data;
// });
// export const fetchSessionsForSection = createAsyncThunk('enrollments/fetchSessionsForSection', async ({ section }) => {
//   const response = await axiosInstance.get('/sessions/section/' + section);
//   return response.data;
// });
export const fetchSessionsForReenrollment = createAsyncThunk('enrollments/fetchSessionsForReenrollment', async ({ id, search = null, filters = null }) => {
  let queryString = '?';
  if (search !== null)
    queryString += 'search=' + encodeURIComponent(search);
  if (filters !== null) {
    if (queryString !== '?')
      queryString += '&';
    queryString += 'filters=' + encodeURIComponent(JSON.stringify(filters));
  }
  const response = await axiosInstance.get('/sessions/reenrollment/' + id + (queryString === '?' ? '' : queryString));
  return response.data;
});
export const fetchEnrollments = createAsyncThunk('enrollments/fetchEnrollments', async ({ search, filters, sorting, page = 0 }) => {
  const response = await axiosInstance.get(`/enrollments?search=${encodeURIComponent(search)}&filters=${encodeURIComponent(JSON.stringify(filters))}&sorting=${encodeURIComponent(JSON.stringify(sorting))}&page=${page}`);
  return response.data;
});
export const fetchEnrollment = createAsyncThunk('enrollments/fetchEnrollment', async ({ id }) => {
  const response = await axiosInstance.get(`/enrollments/${id}`);
  return response.data;
});
export const updateFuturePedagoStatus = createAsyncThunk('enrollments/updateFuturePedagoStatus', async ({ id, status, search = null, filters = null }) => {
  const response = await axiosInstance.put(`/future/pedagoStatus`, { id, status, search, filters });
  return response.data;
});
export const updateFutureDestination = createAsyncThunk('enrollments/updateFutureDestination', async ({ id, destination, search = null, filters = null }) => {
  const response = await axiosInstance.put(`/future/destination`, { id, destination, search, filters });
  return response.data;
});
export const reenroll = createAsyncThunk('enrollments/reenroll', async ({ id, session, search = null, filters = null }) => {
  const response = await axiosInstance.post(`/reenroll`, { id, session, search, filters });
  return response.data;
});
export const getExport = createAsyncThunk('enrollments/getExport', async ({ reportType, ids = null, search = null, filters = null }) => {
  let apiUrl;
  if ((ids !== null) && (ids.length > 0))
    apiUrl = `/report?type=${encodeURIComponent(reportType)}&enrollments=${ids.join(',')}`;
  else
    apiUrl = `/report?type=${encodeURIComponent(reportType)}&search=${encodeURIComponent(search)}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  const response = await axiosInstance.get(apiUrl, { responseType: 'blob' });
  return response.data;
});

export const enrollmentsSlice = createSlice({
  name: 'enrollments',
  initialState: {
    // Filters
    search: '',
    filtersLoaded: false,
    filtersDisabled: true,
    filters: {
      years: {
        values: [],
        selected: '',
      },
      sections: {
        values: [],
        selected: '',
      },
      campuses: {
        values: [],
        selected: '',
      },
      promos: {
        values: [],
        selected: '',
      },
      admStatuses: {
        values: [],
        selected: '',
      },
      pedagoStatuses: {
        values: [],
        selected: '',
      },
      scholarships: {
        values: [],
        selected: '',
      },
      scholarshipsLevels: {
        values: [],
        selected: '',
      },
      specialScholarships: {
        values: [],
        selected: '',
      },
      destinations: {
        values: [],
        selected: '',
      },
      futures: {
        values: [],
        selected: '',
      },
      futureDestinations: {
        values: [],
        selected: '',
      },
      reenrollmentStatuses: {
        values: [],
        selected: '',
      },
      seniorities: {
        values: [],
        selected: '',
      },
      financialStatuses: {
        values: [],
        selected: '',
      },
    },
    computedFilters: {
      year: '',
      section: '',
      campus: '',
      promo: '',
      admStatus: '',
      pedagoStatus: '',
      scholarship: '',
      scholarshipLevel: '',
      specialScholarship: '',
      destination: '',
      future: '',
      futureDestination: '',
      reenrollmentStatus: '',
      seniority: '',
      financialStatus: '',
    },
    futures: [],
    destinations: [],
    // Enrollment List
    enrollments: [],
    enrollmentsLoading: true,
    enrollmentsDestinationSettable: false,
    enrollmentsTotal: 0,
    sorting: { field: 'login', direction: 1 },
    displayCheckboxes: false,
    selectedEnrollments: [],
    selectedEnrollmentsDestinationSettable: false,
    // Enrollment Details
    enrollmentId: null,
    enrollment: null,
    enrollmentYears: [],
    enrollmentCursus: [],
    // Reenrollment
    preferedSession: null,
    sessions: [],
    // API
    apiResponse: {
      status: null,
      thunk: null,
    }
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFiltersDisabled: (state, action) => {
      state = _setFiltersDisabled(state, action.payload);
    },
    resetFilters: (state) => {
      state = _resetFilters(state);
    },
    setFilterValues: (state, action) => {
      state = _setFiltersValues(state, action.payload);
    },
    setFilterSelectedValue: (state, action) => {
      state.filters[action.payload.name].selected = action.payload.value;
    },
    setComputedFilters: (state, action) => {
      state.computedFilters = action.payload;
    },
    resetEnrollments: (state) => {
      if (state.displayCheckboxes === true) {
        state.enrollments = state.enrollments.filter((enrollment) => state.selectedEnrollments.find((selectedEnrollment) => selectedEnrollment._id === enrollment._id));
      } else
        state.enrollments = [];
    },
    setEnrollmentsLoading: (state) => {
      state.enrollmentsLoading = true;
    },
    toggleSelectedEnrollment: (state, action) => {
      const found = state.selectedEnrollments.find((enrollment) => enrollment._id === action.payload._id);
      if (found === undefined) {
        state.selectedEnrollments = state.selectedEnrollments.concat(action.payload);
      } else {
        state.selectedEnrollments = state.selectedEnrollments.filter((enrollment) => enrollment._id !== action.payload._id);
      }
      state.selectedEnrollmentsDestinationSettable = _setDestinationSettable(state.selectedEnrollments);
    },
    setCheckboxes: (state, action) => {
      state.displayCheckboxes = action.payload;
      if (action.payload === false) {
        state.selectedEnrollments = [];
        state.selectedEnrollmentsDestinationSettable = false;
      }
    },
    setSorting: (state, action) => {
      if (state.sorting.field === action.payload)
        state.sorting.direction = state.sorting.direction * -1;
      else
        state.sorting.direction = 1;
      state.sorting.field = action.payload;
    },
    resetApiResponse: (state) => {
      state.apiResponse.status = null;
      state.apiResponse.thunk = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFilters.pending, (state) => {
        _setFiltersLoaded(state, false);
        _setFiltersDisabled(state, true);
        _resetFilters(state);
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        _setFiltersValues(state, action.payload);
        _setFiltersDisabled(state, false);
        _setFiltersLoaded(state, true);
      })
      .addCase(fetchFutures.fulfilled, (state, action) => {
        state.futures = action.payload.map((value, index) => {
          if ((value === undefined) || (value === null))
            return null;
          let valueText = value;
          if (value === true) valueText = 'Oui';
          if (value === false) valueText = 'Non';
          if (value === '') value = '*empty*';
          if (value === '*empty*') valueText = 'Vide';
          if (value === '*void*') {
            value = '';
            valueText = '';
          }
          return { value, label: valueText };
        });
        state.futures.unshift({ value: '*empty*', label: 'Vide' });
      })
      .addCase(fetchDestinations.fulfilled, (state, action) => {
        state.destinations = action.payload.map((value, index) => {
          if ((value === undefined) || (value === null))
            return null;
          let valueText = value;
          if (value === true) valueText = 'Oui';
          if (value === false) valueText = 'Non';
          if (value === '') value = '*empty*';
          if (value === '*empty*') valueText = 'Vide';
          if (value === '*void*') {
            value = '';
            valueText = '';
          }
          return { value, label: valueText };
        });
      })
      .addCase(fetchSessionsForReenrollment.pending, (state) => {
        state.preferedSession = null;
        state.sessions = [];
      })
      .addCase(fetchSessionsForReenrollment.fulfilled, (state, action) => {
        state.preferedSession = action.payload.preferedSession;
        state.sessions = action.payload.sessions;
      })
      .addCase(fetchEnrollments.fulfilled, (state, action) => {
        const enrollments = action.payload.data.filter((enrollment) => state.enrollments.find((enrollmentInState) => enrollmentInState._id === enrollment._id) === undefined);
        state.enrollmentsTotal = action.payload.count;
        state.enrollments = state.enrollments.concat(enrollments);
        state.enrollmentsDestinationSettable = _setDestinationSettable(action.payload.data);
        state.enrollmentsLoading = false;
      })
      .addCase(fetchEnrollment.fulfilled, (state, action) => {
        state.enrollment = action.payload.data;
        state.enrollment.destinationSettable = ((action.payload.data.school === 'Epitech') && (action.payload.data.section === 'PGE 3'));
        state.enrollmentYears = action.payload.years;
        state.enrollmentCursus = action.payload.cursus;
      })
      .addCase(updateFuturePedagoStatus.fulfilled, (state) => {
        state.apiResponse.status = 'success';
        state.apiResponse.thunk = 'updateFuturePedagoStatus';
      })
      .addCase(updateFutureDestination.fulfilled, (state) => {
        state.apiResponse.status = 'success';
        state.apiResponse.thunk = 'updateFutureDestination';
      })
      .addCase(reenroll.fulfilled, (state) => {
        state.apiResponse.status = 'success';
        state.apiResponse.thunk = 'reenroll';
      })
      .addCase(getExport.fulfilled, (state, action) => {
        const blob = new Blob([action.payload], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const date = new Date();
        const dateString = `${date.getFullYear()}${prefixZero(date.getMonth() + 1)}${prefixZero(date.getDate())}-${prefixZero(date.getHours())}${prefixZero(date.getMinutes())}`;
        link.href = url;
        link.setAttribute('download', `export-isee-${dateString}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .addMatcher(isRejectedAction, (state, action) => {
        if (action.error.message === 'Request failed with status code 403') {
          if (action.type.includes('updateFuturePedagoStatus') || action.type.includes('updateFutureDestination') || action.type.includes('reenroll')) {
            state.apiResponse.status = 'error';
            state.apiResponse.thunk = action.type.split('/')[1];
          }
        }
      });
  },
})

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setFiltersDisabled,
  resetFilters,
  setFilterValues,
  setFilterSelectedValue,
  setComputedFilters,
  resetEnrollments,
  setEnrollmentsLoading,
  toggleSelectedEnrollment,
  resetEnrollment,
  setCheckboxes,
  setSorting,
  resetApiResponse,
} = enrollmentsSlice.actions

export default enrollmentsSlice.reducer